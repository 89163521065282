import contextMenu from "./menu.vue";
export default {
  install: function (Vue) {
    const contructor = Vue.extend(contextMenu);
    let instance = null;
    let EL = null;
    // 菜单组件显隐
    Vue.$showContextMenu = function (options) {
      if (!instance) instance = new contructor();
      if (!EL) {
        EL = instance.$mount().$el;
        document.body.appendChild(EL);
      }
      instance.show(options);
    };
    // 移除菜单dom
    Vue.$hideContextMenu = function () {
      if (!EL) return;
      instance.close();
      document.body.removeChild(EL);
      EL = null;
    };
  },
};
