<template>
  <div
    ref="contextMenu"
    class="context-menu"
    :style="{
      display: contextMenuStatus,
      top: contextMenuTop,
      left: contextMenuLeft,
    }"
  >
    <div class="menu-item" v-for="(item, index) in menuList" :key="index" @click="item.handler">
      <i v-if="item.icon" :class="item.icon"></i>
      <span>
        {{ item.name }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "contextMenu",
  computed: {},
  data() {
    return {
      contextMenuStatus: "none",
      contextMenuTop: "0px",
      contextMenuLeft: "0px",
      menuList: [],
    };
  },
  methods: {
    /**
     *  检查元素是否处于给定元素的右边界或下边界后再给菜单的top和left复制
     *  @param {String} elId 给定元素的id 没有则默认为body
     *  @param {Number} left 原left值
     *  @param {String} top 原top值
     */

    setLetRight(left, top) {
      this.$nextTick(() => {
        const rect = document.documentElement.getBoundingClientRect();
        // left
        const distanceToRight = rect.right - left;
        const width = this.$refs.contextMenu.clientWidth;
        if (distanceToRight < width) {
          this.contextMenuLeft = left - width + "px";
        } else {
          this.contextMenuLeft = left + "px";
        }
        // top
        const distanceToBottom = rect.bottom - top;
        const height = this.$refs.contextMenu.clientHeight;
        if (distanceToBottom < height) {
          this.contextMenuTop = top - height + "px";
        } else {
          this.contextMenuTop = top + "px";
        }
      });
    },
    show(options = {}) {
      this.contextMenuStatus = options.contextMenuStatus;
      if (options.isHide) return; // 只隐藏菜单组件
      let top = options.contextMenuTop;
      let left = options.contextMenuLeft;
      this.setLetRight(left, top);
      this.menuList = options.menuList || [];
    },
    close() {
      this.contextMenuStatus = "none";
      this.contextMenuTop = "0px";
      this.contextMenuLeft = "0px";
      this.menuList = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.context-menu {
  position: fixed;
  z-index: 10000000;
  height: auto;
  background-color: #fff;
  display: none;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  .menu-item {
    display: flex;
    align-items: center;
    padding: 5px 5px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      background-color: #eef4ff;
      color: #488af8;
    }
    i {
      margin-right: 5px;
    }
  }
}
</style>